import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useAuth } from '../context/AuthContext';
import './AdminDashboard.css';

const AdminDashboard = () => {
  const { currentUser, logout } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [roleRequests, setRoleRequests] = useState([]);
  const [activeTab, setActiveTab] = useState('overview');
  const [searchTerm, setSearchTerm] = useState('');
  const [confirmAction, setConfirmAction] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [sendingMessage, setSendingMessage] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [showMessageForm, setShowMessageForm] = useState(false);
  const [showHtmlGuide, setShowHtmlGuide] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [uploadingCharacterSheet, setUploadingCharacterSheet] = useState(false);
  const [characterSheetError, setCharacterSheetError] = useState('');
  const [characterSheetSuccess, setCharacterSheetSuccess] = useState('');
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryFolders, setGalleryFolders] = useState([]);
  const [galleryCategories, setGalleryCategories] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [isLoadingGallery, setIsLoadingGallery] = useState(false);
  const [galleryError, setGalleryError] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [filteredImages, setFilteredImages] = useState([]);
  const [activeFilter, setActiveFilter] = useState(null);
  const [sortCriteria, setSortCriteria] = useState("name");
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [quickEditUser, setQuickEditUser] = useState(null);
  const [showQuickRoleEdit, setShowQuickRoleEdit] = useState(false);
  const [batchRole, setBatchRole] = useState("");
  const [showBatchRoleEdit, setShowBatchRoleEdit] = useState(false);
  const [editUserProfile, setEditUserProfile] = useState(false);
  const [userProfileData, setUserProfileData] = useState({
    displayName: '',
    email: ''
  });
  const [profileUpdateMessage, setProfileUpdateMessage] = useState({ type: '', message: '' });
  
  // Add these missing state variables to fix the errors
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';

  useEffect(() => {
    fetchUsers();
    fetchRoleRequests();
  }, []);

  useEffect(() => {
    if (activeTab === 'gallery' && galleryCategories.length === 0 && !isLoadingGallery) {
      fetchGalleryCategories();
    }
  }, [activeTab, galleryCategories.length, isLoadingGallery]);

  const fetchUsers = async () => {
    setLoading(true);
    setError('');
    
    try {
      console.log('Fetching users from:', `${API_URL}/admin/users`);
      const response = await fetch(`${API_URL}/admin/users`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || `Server responded with status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Users fetched successfully:', data);
      setUsers(data);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError(`Error fetching users: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const fetchRoleRequests = async () => {
    try {
      console.log('Fetching role requests from:', `${API_URL}/admin/role-requests`);
      const response = await fetch(`${API_URL}/admin/role-requests`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Error response:', errorData);
        return; // Don't set error state here to avoid overriding user fetch errors
      }
      
      const data = await response.json();
      console.log('Role requests fetched successfully:', data);
      setRoleRequests(data);
    } catch (err) {
      console.error('Error fetching role requests:', err);
      // Don't set error state here to avoid overriding user fetch errors
    }
  };

  const fetchNotifications = async (silent = false) => {
    try {
      if (!silent) setLoading(true);
      
      const response = await fetch(`${API_URL}/admin/notifications`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Error response:', errorData);
        return;
      }
      
      const data = await response.json();
      console.log('Notifications fetched successfully:', data);
      
      // Count unread notifications
      const unread = data.filter(notification => !notification.read).length;
      setUnreadNotifications(unread);
      
      setNotifications(data);
    } catch (err) {
      console.error('Error fetching notifications:', err);
    } finally {
      if (!silent) setLoading(false);
    }
  };

  const markNotificationAsRead = async (notificationId) => {
    console.log('Notification marking as read is not implemented');
  };

  const handleUserSelect = (user) => {
    if (selectedUser && selectedUser.id === user.id) {
      // If clicking the same user, toggle the details closed
      setSelectedUser(null);
      setShowUserDetails(false);
    } else {
      // If clicking a different user, show that user's details
      setSelectedUser(user);
      setShowUserDetails(true);
      // Initialize profile edit data when selecting a user
      setUserProfileData({
        displayName: user.displayName || '',
        email: user.email || ''
      });
    }
    setShowMessageForm(false);
    setEditUserProfile(false);
    setCharacterSheetError('');
    setCharacterSheetSuccess('');
    setProfileUpdateMessage({ type: '', message: '' });
  };

  const handleBackToList = () => {
    setShowUserDetails(false);
  };

  const handleRoleChange = async (userId, newRole) => {
    try {
      const response = await fetch(`${API_URL}/admin/users/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify({ role: newRole })
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to update user role');
      }
      
      // Update local state
      setUsers(users.map(user => 
        user.id === userId ? { ...user, role: newRole } : user
      ));
      
      if (selectedUser && selectedUser.id === userId) {
        setSelectedUser({ ...selectedUser, role: newRole });
      }
      
      // If this was a role request, refresh the requests
      fetchRoleRequests();
      setError(''); // Clear any previous errors
    } catch (err) {
      console.error('Error updating user role:', err);
      setError(`Error updating user role: ${err.message}`);
    }
  };

  const handlePasswordReset = async (userId) => {
    setConfirmAction({
      type: 'resetPassword',
      userId,
      message: 'Are you sure you want to reset this user\'s password?',
      confirmText: 'Reset Password',
      action: async () => {
        try {
          const response = await fetch(`${API_URL}/admin/users/${userId}/reset-password`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': localStorage.getItem('auth_token')
            },
            body: JSON.stringify({ newPassword: 'tempPassword123' }) // Default temporary password
          });
          
          if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.message || 'Failed to reset password');
          }
          
          setError('');
          alert('Password has been reset to: tempPassword123');
        } catch (err) {
          console.error('Error resetting password:', err);
          setError(`Error resetting password: ${err.message}`);
        } finally {
          setConfirmAction(null);
        }
      }
    });
  };

  const handleDeleteUser = async (userId) => {
    setConfirmAction({
      type: 'deleteUser',
      userId,
      message: 'Are you sure you want to delete this user? This action cannot be undone.',
      confirmText: 'Delete User',
      action: async () => {
        try {
          const response = await fetch(`${API_URL}/admin/users/${userId}`, {
            method: 'DELETE',
            headers: {
              'x-auth-token': localStorage.getItem('auth_token')
            }
          });
          
          if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.message || 'Failed to delete user');
          }
          
          // Update local state
          setUsers(users.filter(user => user.id !== userId));
          
          if (selectedUser && selectedUser.id === userId) {
            setSelectedUser(null);
          }
          
          setError('');
        } catch (err) {
          console.error('Error deleting user:', err);
          setError(`Error deleting user: ${err.message}`);
        } finally {
          setConfirmAction(null);
        }
      }
    });
  };

  const handleApproveRequest = async (requestId, userId) => {
    try {
      // First update the user's role
      await handleRoleChange(userId, 'adventurer');
      
      // Then mark the request as approved
      const response = await fetch(`${API_URL}/admin/role-requests/${requestId}/approve`, {
        method: 'PUT',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to approve request');
      }
      
      // Update local state
      setRoleRequests(roleRequests.filter(req => req.id !== requestId));
      setError(''); // Clear any previous errors
    } catch (err) {
      console.error('Error approving request:', err);
      setError(`Error approving request: ${err.message}`);
    }
  };

  const handleDenyRequest = async (requestId) => {
    try {
      const response = await fetch(`${API_URL}/admin/role-requests/${requestId}/deny`, {
        method: 'PUT',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to deny request');
      }
      
      // Update local state
      setRoleRequests(roleRequests.filter(req => req.id !== requestId));
      setError(''); // Clear any previous errors
    } catch (err) {
      console.error('Error denying request:', err);
      setError(`Error denying request: ${err.message}`);
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    
    if (!selectedUser) {
      setMessageError('No user selected');
      return;
    }
    
    if (!messageText.trim()) {
      setMessageError('Message cannot be empty');
      return;
    }
    
    setSendingMessage(true);
    setMessageError('');
    
    try {
      // Log the request details for debugging
      console.log('Sending message to user ID:', selectedUser.id);
      console.log('Message content:', messageText);
      
      // Try the endpoint with user ID in the path
      const endpoint = `${API_URL}/admin/users/${selectedUser.id}/message`;
      console.log('Using API endpoint:', endpoint);
      
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify({
          message: messageText,
          fromAdmin: true,
          // Add a title for the message so it's more visible
          title: "Admin Message",
          // Make sure to set a flag to display this in the user dashboard
          showInDashboard: true
        })
      });
      
      // Log the response for debugging
      console.log('Response status:', response.status);
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Error response data:', errorData);
        throw new Error(errorData.message || `Failed to send message: ${response.status}`);
      }
      
      // Clear the message form
      setMessageText('');
      setShowMessageForm(false);
      alert(`Message sent to ${selectedUser.displayName}`);
    } catch (err) {
      console.error('Error sending message:', err);
      setMessageError(`Error sending message: ${err.message}`);
      
      // Show a more detailed error message to help with debugging
      alert(`Failed to send message. Error: ${err.message}\nPlease check the console for more details.`);
    } finally {
      setSendingMessage(false);
    }
  };

  const handleCharacterSheetUpload = async (e) => {
    const file = e.target.files[0];
    if (!file || !selectedUser) return;
    
    // Check file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setCharacterSheetError('File too large. Maximum size is 5MB.');
      return;
    }
    
    // Check file type
    if (file.type !== 'application/json') {
      setCharacterSheetError('Only JSON files are allowed.');
      return;
    }
    
    setUploadingCharacterSheet(true);
    setCharacterSheetError('');
    setCharacterSheetSuccess('');
    
    try {
      // Create FormData object for file upload
      const formData = new FormData();
      formData.append('characterSheet', file);
      
      // Add user ID to the form data
      formData.append('userId', selectedUser.id);
      
      // Upload the file to the server
      console.log('Uploading character sheet for user:', selectedUser.id);
      const response = await fetch(`${API_URL}/admin/upload/character-sheet`, {
        method: 'POST',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: formData
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to upload character sheet');
      }
      
      const data = await response.json();
      console.log('Character sheet uploaded successfully:', data);
      
      setCharacterSheetSuccess('Character sheet uploaded successfully');
      
      // Clear the success message after 3 seconds
      setTimeout(() => {
        setCharacterSheetSuccess('');
      }, 3000);
    } catch (err) {
      setCharacterSheetError(err.message || 'An error occurred during upload');
      console.error('Error uploading character sheet:', err);
    } finally {
      setUploadingCharacterSheet(false);
      
      // Reset the file input
      if (e.target) {
        e.target.value = '';
      }
    }
  };

  const handleRetry = () => {
    setError('');
    setActiveTab('users');
    fetchUsers();
    fetchRoleRequests();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };
  
  // Function to handle inserting HTML formatting tags
  const insertFormatting = (type) => {
    // Get textarea element
    const textarea = document.querySelector('.message-form textarea');
    if (!textarea) return;
    
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = messageText.substring(start, end);
    let replacement = '';
    
    // Insert appropriate HTML based on the formatting type
    switch (type) {
      case 'bold':
        replacement = `<strong>${selectedText || 'bold text'}</strong>`;
        break;
      case 'italic':
        replacement = `<em>${selectedText || 'italic text'}</em>`;
        break;
      case 'heading':
        replacement = `<h3>${selectedText || 'Heading'}</h3>`;
        break;
      case 'paragraph':
        replacement = `<p>${selectedText || 'New paragraph'}</p>`;
        break;
      case 'blockquote':
        replacement = `<blockquote>${selectedText || 'Quoted text'}</blockquote>`;
        break;
      case 'list':
        replacement = `<ul>\n  <li>${selectedText || 'List item'}</li>\n  <li>Another item</li>\n</ul>`;
        break;
      default:
        return;
    }
    
    // Insert the HTML at the cursor position or replace selected text
    const newText = messageText.substring(0, start) + replacement + messageText.substring(end);
    setMessageText(newText);
    
    // Focus back on textarea after inserting
    setTimeout(() => {
      textarea.focus();
      textarea.setSelectionRange(start + replacement.length, start + replacement.length);
    }, 0);
  };

  // Filter users based on search term
  const filteredUsers = users.filter(user => 
    user.displayName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.role?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Apply role filter and sorting
  const filteredAndSortedUsers = useMemo(() => {
    // First, apply the role filter if one is active
    let filteredList = [...filteredUsers];
    
    if (activeFilter) {
      filteredList = filteredList.filter(user => user.role === activeFilter);
    }
    
    // Then, sort the list based on selected criteria
    return filteredList.sort((a, b) => {
      switch (sortCriteria) {
        case 'name':
          return (a.displayName || '').localeCompare(b.displayName || '');
        case 'role':
          return (a.role || '').localeCompare(b.role || '');
        case 'recent':
          return new Date(b.createdAt) - new Date(a.createdAt);
        default:
          return 0;
      }
    });
  }, [filteredUsers, activeFilter, sortCriteria]);

  const handleLogout = () => {
    logout();
  };

  const fetchGalleryCategories = async () => {
    try {
      setIsLoadingGallery(true);
      setGalleryError('');
      
      const API_URL = window.location.hostname === 'localhost' 
        ? 'http://localhost:3001/api'
        : 'https://firstveil.com/api';
      
      const response = await fetch(`${API_URL}/gallery/categories`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setGalleryCategories(data.categories);
      } else {
        setGalleryError('Failed to load gallery categories');
      }
    } catch (error) {
      console.error('Error fetching gallery categories:', error);
      setGalleryError('An error occurred while loading the gallery');
    } finally {
      setIsLoadingGallery(false);
    }
  };

  const fetchFolderImages = async (category, folderPath) => {
    try {
      setIsLoadingGallery(true);
      setGalleryError('');
      
      console.log(`Fetching images from: category=${category}, folder=${folderPath}`);
      
      let url;
      if (!folderPath || folderPath === '') {
        // For direct image folders, don't include the folderPath in the URL
        url = `${API_URL}/gallery/folder/${category}`;
      } else {
        url = `${API_URL}/gallery/folder/${category}/${folderPath}`;
      }
      
      console.log('Request URL:', url);
      
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        console.log(`Received ${data.images.length} images:`, data.images);
        setGalleryImages(data.images);
        setCurrentFolder({ category, path: folderPath });
      } else {
        const errorData = await response.text();
        console.error('Failed to load folder images:', errorData);
        setGalleryError(`Failed to load folder images: ${errorData}`);
      }
    } catch (error) {
      console.error('Error fetching folder images:', error);
      setGalleryError('An error occurred while loading the images');
    } finally {
      setIsLoadingGallery(false);
    }
  };

  const handleBackToFolders = () => {
    setCurrentFolder(null);
    setGalleryImages([]);
  };

  const handleFolderClick = (category, folder) => {
    console.log('Folder clicked:', category, folder);
    
    // Handle direct image folders (which have empty path)
    const folderPath = folder.path || '';
    
    console.log(`Requesting images for category=${category}, folderPath=${folderPath}`);
    fetchFolderImages(category, folderPath);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const handleMarkAllAsRead = async () => {
    console.log('Mark all as read is not implemented');
  };

  const handleMarkAsRead = async (notificationId) => {
    console.log('Mark as read is not implemented');
  };

  const handleDeleteNotification = async (notificationId) => {
    console.log('Delete notification is not implemented');
  };

  const handleSelectAll = () => {
    if (filteredUsers.length > 0) {
      if (selectedUserIds.length === filteredUsers.length) {
        setSelectedUserIds([]);
      } else {
        setSelectedUserIds(filteredUsers.map(user => user.id));
      }
    }
  };

  const handleUserCheckboxToggle = (userId) => {
    if (selectedUserIds.includes(userId)) {
      setSelectedUserIds(selectedUserIds.filter(id => id !== userId));
    } else {
      setSelectedUserIds([...selectedUserIds, userId]);
    }
  };

  const handleBatchRoleChange = () => {
    setShowBatchRoleEdit(true);
  };

  const saveBatchRoleEdit = async () => {
    try {
      if (selectedUserIds.length > 0 && batchRole) {
        for (const userId of selectedUserIds) {
          await handleRoleChange(userId, batchRole);
        }
        setSelectedUserIds([]);
        setBatchRole("");
        setShowBatchRoleEdit(false);
      }
    } catch (err) {
      console.error('Error batch role change:', err);
      setError(`Error batch role change: ${err.message}`);
    }
  };

  const handleBatchDelete = () => {
    if (selectedUserIds.length === 0) return;
    
    setConfirmAction({
      type: 'batchDeleteUsers',
      message: `Are you sure you want to delete ${selectedUserIds.length} users? This action cannot be undone.`,
      confirmText: 'Delete Users',
      action: async () => {
        try {
          for (const userId of selectedUserIds) {
            const response = await fetch(`${API_URL}/admin/users/${userId}`, {
              method: 'DELETE',
              headers: {
                'x-auth-token': localStorage.getItem('auth_token')
              }
            });
            
            if (!response.ok) {
              const errorData = await response.json().catch(() => ({}));
              throw new Error(errorData.message || 'Failed to delete some users');
            }
          }
          
          // Update local state
          setUsers(users.filter(user => !selectedUserIds.includes(user.id)));
          
          if (selectedUser && selectedUserIds.includes(selectedUser.id)) {
            setSelectedUser(null);
          }
          
          setSelectedUserIds([]);
          setError('');
        } catch (err) {
          console.error('Error deleting users:', err);
          setError(`Error deleting users: ${err.message}`);
        } finally {
          setConfirmAction(null);
        }
      }
    });
  };

  const handleQuickRoleChange = (newRole) => {
    if (quickEditUser) {
      setQuickEditUser({...quickEditUser, role: newRole});
    }
  };

  const saveQuickRoleEdit = async () => {
    if (quickEditUser) {
      await handleRoleChange(quickEditUser.id, quickEditUser.role);
      setQuickEditUser(null);
      setShowQuickRoleEdit(false);
    }
  };

  // Add function to handle user profile updates
  const handleUserProfileUpdate = async () => {
    if (!selectedUser) return;
    
    try {
      const response = await fetch(`${API_URL}/admin/users/${selectedUser.id}/profile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify(userProfileData)
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to update user profile');
      }
      
      // Update local state
      setUsers(users.map(user => 
        user.id === selectedUser.id ? { ...user, ...userProfileData } : user
      ));
      
      setSelectedUser({ ...selectedUser, ...userProfileData });
      
      setProfileUpdateMessage({ 
        type: 'success', 
        message: 'Profile updated successfully' 
      });
      
      setTimeout(() => {
        setProfileUpdateMessage({ type: '', message: '' });
      }, 3000);
      
      setEditUserProfile(false);
    } catch (err) {
      console.error('Error updating user profile:', err);
      setProfileUpdateMessage({ 
        type: 'error', 
        message: `Error updating profile: ${err.message}` 
      });
    }
  };

  return (
    <div className="admin-dashboard">
      {/* Header with logout button */}
      <div className="dashboard-header">
        <h1>First Veil</h1>
        <button 
          className="logout-button"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>

      <div className="dashboard-tabs">
        <button 
          className={`tab-button ${activeTab === 'overview' ? 'active' : ''}`}
          onClick={() => setActiveTab('overview')}
        >
          Dashboard
        </button>
        <button 
          className={`tab-button ${activeTab === 'users' ? 'active' : ''}`}
          onClick={() => setActiveTab('users')}
        >
          User Management
        </button>
        <button 
          className={`tab-button ${activeTab === 'requests' ? 'active' : ''}`}
          onClick={() => setActiveTab('requests')}
        >
          Role Requests {roleRequests.length > 0 && <span className="request-badge">{roleRequests.length}</span>}
        </button>
        <button 
          className={`tab-button ${activeTab === 'gallery' ? 'active' : ''}`}
          onClick={() => setActiveTab('gallery')}
        >
          Gallery
        </button>
        <button 
          className={`tab-button ${activeTab === 'links' ? 'active' : ''}`}
          onClick={() => setActiveTab('links')}
        >
          Links
        </button>
      </div>
      
      {error && (
        <div className="admin-error">
          <p>{error}</p>
          <button className="retry-button" onClick={handleRetry}>Retry</button>
        </div>
      )}
      
      <div className="tab-content">
        {activeTab === 'overview' && (
          <div className="overview-tab">
            <div className="dashboard-stats">
              <div className="stat-card">
                <h3>Total Users</h3>
                <div className="stat-value">{users.length}</div>
              </div>
              <div className="stat-card">
                <h3>Adventurers</h3>
                <div className="stat-value">
                  {users.filter(user => user.role === 'adventurer').length}
                </div>
              </div>
              <div className="stat-card">
                <h3>Pending Requests</h3>
                <div className="stat-value">{roleRequests.length}</div>
              </div>
              <div className="stat-card">
                <h3>Gallery Categories</h3>
                <div className="stat-value">{galleryCategories.length}</div>
              </div>
            </div>

            <div className="dashboard-sections">
              <div className="activity-section">
                <h3>Recent Activity</h3>
                <div className="activity-list">
                  {roleRequests.length > 0 ? (
                    <div className="activity-item">
                      <div className="activity-icon request-icon">🔔</div>
                      <div className="activity-content">
                        <div className="activity-title">{roleRequests.length} pending adventurer requests</div>
                        <div className="activity-action">
                          <button 
                            className="action-button small"
                            onClick={() => setActiveTab('requests')}
                          >
                            Review
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="no-activity">No recent activity</div>
                  )}
                </div>
              </div>

              <div className="quick-actions">
                <h3>Quick Actions</h3>
                <div className="quick-action-buttons">
                  <button 
                    className="dashboard-action-button"
                    onClick={() => setActiveTab('users')}
                  >
                    <div className="action-icon">👥</div>
                    <div className="action-label">Manage Users</div>
                  </button>
                  
                  <button 
                    className="dashboard-action-button"
                    onClick={() => setActiveTab('requests')}
                  >
                    <div className="action-icon">🔔</div>
                    <div className="action-label">Review Requests</div>
                  </button>
                  
                  <button 
                    className="dashboard-action-button"
                    onClick={() => setActiveTab('gallery')}
                  >
                    <div className="action-icon">🖼️</div>
                    <div className="action-label">Gallery</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'users' && (
          <div className="users-tab">
            <div className="users-control-panel">
              <div className="filter-controls">
                <div className="search-bar">
                  <input
                    type="text"
                    placeholder="Search users..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="role-filters">
                  <button 
                    className={`filter-button ${!activeFilter ? 'active' : ''}`}
                    onClick={() => setActiveFilter(null)}
                  >
                    All Users
                  </button>
                  <button 
                    className={`filter-button ${activeFilter === 'admin' ? 'active' : ''}`}
                    onClick={() => setActiveFilter('admin')}
                  >
                    Admins
                  </button>
                  <button 
                    className={`filter-button ${activeFilter === 'adventurer' ? 'active' : ''}`}
                    onClick={() => setActiveFilter('adventurer')}
                  >
                    Adventurers
                  </button>
                  <button 
                    className={`filter-button ${activeFilter === 'commoner' ? 'active' : ''}`}
                    onClick={() => setActiveFilter('commoner')}
                  >
                    Commoners
                  </button>
                </div>
              </div>
              <div className="user-actions-toolbar">
                <div className="sort-controls">
                  <label>Sort by:</label>
                  <select 
                    value={sortCriteria} 
                    onChange={(e) => setSortCriteria(e.target.value)}
                    className="sort-dropdown"
                  >
                    <option value="name">Name</option>
                    <option value="role">Role</option>
                    <option value="recent">Recently Joined</option>
                  </select>
                </div>
                <div className="batch-selection-controls">
                  <button 
                    className="action-button"
                    onClick={handleSelectAll}
                    disabled={filteredAndSortedUsers.length === 0}
                  >
                    {selectedUserIds.length === filteredAndSortedUsers.length && selectedUserIds.length > 0 ? 'Deselect All' : 'Select All'}
                  </button>
                  {selectedUserIds.length > 0 && (
                    <div className="batch-actions">
                      <button 
                        className="action-button"
                        onClick={handleBatchRoleChange}
                      >
                        Set Role
                      </button>
                      <button 
                        className="action-button delete-user"
                        onClick={handleBatchDelete}
                      >
                        Delete Selected
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            
            {loading ? (
              <div className="loading-users">
                <div className="loading-spinner"></div>
                <p>Loading users...</p>
              </div>
            ) : (
              <div className="users-list">
                {filteredAndSortedUsers.length > 0 ? (
                  filteredAndSortedUsers.map(user => (
                    <div key={user.id}>
                      <div 
                        className={`user-item ${selectedUser && selectedUser.id === user.id ? 'selected' : ''} ${selectedUserIds.includes(user.id) ? 'batch-selected' : ''}`}
                      >
                        <div className="user-select">
                          <input 
                            type="checkbox" 
                            checked={selectedUserIds.includes(user.id)}
                            onChange={() => handleUserCheckboxToggle(user.id)}
                            onClick={(e) => e.stopPropagation()}
                          />
                        </div>
                        <div 
                          className="user-content"
                          onClick={() => handleUserSelect(user)}
                        >
                          <div className="user-avatar">
                            {user.avatar ? (
                              <img src={user.avatar} alt={user.displayName} />
                            ) : (
                              <div className="avatar-placeholder">
                                {user.displayName?.charAt(0).toUpperCase() || 'U'}
                              </div>
                            )}
                          </div>
                          <div className="user-info">
                            <div className="user-name">{user.displayName}</div>
                            <div className="user-email">{user.email}</div>
                          </div>
                          <div className={`user-role role-${user.role}`}>
                            {user.role}
                          </div>
                          <div className="user-joined">
                            <div className="detail-label">Joined</div>
                            <div>{formatDate(user.createdAt).split(',')[0]}</div>
                          </div>
                          <div className="user-quick-actions">
                            <button 
                              className="icon-button" 
                              title="Change Role"
                              onClick={(e) => {
                                e.stopPropagation();
                                setQuickEditUser(user);
                                setShowQuickRoleEdit(true);
                              }}
                            >
                              👑
                            </button>
                            <button 
                              className="icon-button" 
                              title="Send Message"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedUser(user);
                                setShowMessageForm(true);
                              }}
                            >
                              📧
                            </button>
                            <button 
                              className="icon-button delete" 
                              title="Delete User"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteUser(user.id);
                              }}
                            >
                              🗑️
                            </button>
                          </div>
                        </div>
                      </div>
                      
                      {/* User details panel rendered directly under the selected user */}
                      {selectedUser && selectedUser.id === user.id && showUserDetails && (
                        <div className="user-details-inline">
                          {!editUserProfile ? (
                            <>
                              <div className="detail-item">
                                <div className="detail-label">Email</div>
                                <div className="detail-value">{selectedUser.email}</div>
                              </div>
                              <div className="detail-item">
                                <div className="detail-label">Display Name</div>
                                <div className="detail-value">{selectedUser.displayName}</div>
                              </div>
                              <div className="detail-item">
                                <div className="detail-label">User ID</div>
                                <div className="detail-value">{selectedUser.id}</div>
                              </div>
                              <div className="detail-item">
                                <div className="detail-label">Role</div>
                                <div className="role-selector">
                                  <select 
                                    value={selectedUser.role} 
                                    onChange={(e) => handleRoleChange(selectedUser.id, e.target.value)}
                                  >
                                    <option value="commoner">Commoner</option>
                                    <option value="adventurer">Adventurer</option>
                                    <option value="admin">Admin</option>
                                  </select>
                                </div>
                              </div>
                              <div className="detail-item">
                                <div className="detail-label">Character Sheet</div>
                                <div className="detail-value">
                                  <label className="action-button">
                                    Upload Character Sheet
                                    <input 
                                      type="file" 
                                      accept="application/json" 
                                      onChange={handleCharacterSheetUpload} 
                                      disabled={uploadingCharacterSheet || selectedUser.role !== 'adventurer'}
                                      style={{ display: 'none' }}
                                    />
                                  </label>
                                  {uploadingCharacterSheet && <span className="loading-spinner-small"></span>}
                                  {selectedUser.role !== 'adventurer' && (
                                    <div className="character-sheet-note">
                                      User must be an Adventurer to have a character sheet
                                    </div>
                                  )}
                                  {characterSheetError && (
                                    <div className="message-error">{characterSheetError}</div>
                                  )}
                                  {characterSheetSuccess && (
                                    <div className="message-success">{characterSheetSuccess}</div>
                                  )}
                                </div>
                              </div>
                              <div className="detail-item">
                                <div className="detail-label">Member Since</div>
                                <span className="detail-value">{formatDate(selectedUser.createdAt)}</span>
                              </div>
                              
                              <div className="user-actions">
                                <button 
                                  className="action-button edit-profile"
                                  onClick={() => setEditUserProfile(true)}
                                >
                                  Edit Profile
                                </button>
                                <button 
                                  className="action-button reset-password"
                                  onClick={() => handlePasswordReset(selectedUser.id)}
                                >
                                  Reset Password
                                </button>
                                <button 
                                  className="action-button delete-user"
                                  onClick={() => handleDeleteUser(selectedUser.id)}
                                >
                                  Delete User
                                </button>
                                <button 
                                  className="action-button message-user"
                                  onClick={() => setShowMessageForm(true)}
                                >
                                  Send Message
                                </button>
                              </div>
                            </>
                          ) : (
                            <div className="edit-profile-form">
                              <h4>Edit User Profile</h4>
                              {profileUpdateMessage.message && (
                                <div className={`message-${profileUpdateMessage.type}`}>
                                  {profileUpdateMessage.message}
                                </div>
                              )}
                              
                              <div className="form-group">
                                <label>Display Name</label>
                                <input 
                                  type="text" 
                                  value={userProfileData.displayName}
                                  onChange={(e) => setUserProfileData({
                                    ...userProfileData,
                                    displayName: e.target.value
                                  })}
                                  className="profile-input"
                                />
                              </div>
                              
                              <div className="form-group">
                                <label>Email Address</label>
                                <input 
                                  type="email" 
                                  value={userProfileData.email}
                                  onChange={(e) => setUserProfileData({
                                    ...userProfileData,
                                    email: e.target.value
                                  })}
                                  className="profile-input"
                                />
                              </div>
                              
                              <div className="profile-actions">
                                <button 
                                  type="button" 
                                  className="action-button save-profile"
                                  onClick={handleUserProfileUpdate}
                                >
                                  Save Changes
                                </button>
                                <button 
                                  type="button" 
                                  className="action-button cancel-edit"
                                  onClick={() => {
                                    setEditUserProfile(false);
                                    setUserProfileData({
                                      displayName: selectedUser.displayName || '',
                                      email: selectedUser.email || ''
                                    });
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          )}
                          
                          {/* Message section with proper conditional rendering */}
                          {showMessageForm && (
                            <div className="message-section">
                              <form onSubmit={handleSendMessage} className="message-form">
                                <h4>Send Message to {selectedUser.displayName}</h4>
                                {messageError && <div className="message-error">{messageError}</div>}
                                
                                <textarea
                                  value={messageText}
                                  onChange={(e) => setMessageText(e.target.value)}
                                  placeholder="Enter your message here... HTML formatting is supported."
                                  rows={8}
                                  required
                                />
                                <div className="message-preview">
                                  <h5>Preview:</h5>
                                  <div 
                                    className="preview-content"
                                    dangerouslySetInnerHTML={{ __html: messageText }}
                                  />
                                </div>
                                <div className="message-actions">
                                  <button 
                                    type="submit" 
                                    className="send-message-btn"
                                    disabled={sendingMessage}
                                  >
                                    {sendingMessage ? 'Sending...' : 'Send Message'}
                                  </button>
                                  <button 
                                    type="button" 
                                    className="cancel-message-btn"
                                    onClick={() => {
                                      setShowMessageForm(false);
                                      setMessageText('');
                                      setMessageError('');
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </form>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="no-users">No users found matching your criteria</div>
                )}
              </div>
            )}
            
            {/* Quick role edit modal */}
            {showQuickRoleEdit && quickEditUser && (
              <div className="quick-edit-modal">
                <div className="quick-edit-content">
                  <h3>Change Role: {quickEditUser.displayName}</h3>
                  <div className="role-selector">
                    <select 
                      value={quickEditUser.role} 
                      onChange={(e) => handleQuickRoleChange(e.target.value)}
                    >
                      <option value="commoner">Commoner</option>
                      <option value="adventurer">Adventurer</option>
                      <option value="admin">Admin</option>
                    </select>
                  </div>
                  <div className="quick-edit-actions">
                    <button 
                      className="action-button"
                      onClick={saveQuickRoleEdit}
                    >
                      Save
                    </button>
                    <button 
                      className="cancel-button"
                      onClick={() => {
                        setShowQuickRoleEdit(false);
                        setQuickEditUser(null);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
            
            {/* Batch role change modal */}
            {showBatchRoleEdit && (
              <div className="quick-edit-modal">
                <div className="quick-edit-content">
                  <h3>Change Role for {selectedUserIds.length} Users</h3>
                  <div className="role-selector">
                    <select 
                      value={batchRole} 
                      onChange={(e) => setBatchRole(e.target.value)}
                    >
                      <option value="">Select role...</option>
                      <option value="commoner">Commoner</option>
                      <option value="adventurer">Adventurer</option>
                      <option value="admin">Admin</option>
                    </select>
                  </div>
                  <div className="quick-edit-actions">
                    <button 
                      className="action-button"
                      onClick={saveBatchRoleEdit}
                      disabled={!batchRole}
                    >
                      Save
                    </button>
                    <button 
                      className="cancel-button"
                      onClick={() => {
                        setShowBatchRoleEdit(false);
                        setBatchRole("");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'requests' && (
          <div className="requests-tab">
            
            {loading ? (
              <div className="loading-requests">
                <div className="loading-spinner"></div>
                <p>Loading requests...</p>
              </div>
            ) : roleRequests.length > 0 ? (
              <div className="requests-list">
                {roleRequests.map(request => (
                  <div key={request.id} className="request-item">
                    <div className="request-header">
                      <div className="requester-info">
                        <span className="requester-name">{request.userName}</span>
                        <span className="requester-email">{request.userEmail}</span>
                      </div>
                      <div className="request-date">{formatDate(request.createdAt)}</div>
                    </div>
                    
                    <div className="request-reason">
                      <h4>Reason for Request:</h4>
                      <p>{request.reason}</p>
                    </div>
                    
                    <div className="request-actions">
                      <button 
                        className="action-button approve"
                        onClick={() => handleApproveRequest(request.id, request.userId)}
                      >
                        Approve
                      </button>
                      <button 
                        className="action-button deny"
                        onClick={() => handleDenyRequest(request.id)}
                      >
                        Deny
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-requests">
                <p>No pending role requests</p>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'gallery' && (
          <div className="gallery-tab">
            
            {isLoadingGallery ? (
              <div className="loading-gallery">
                <div className="loading-spinner"></div>
                <p>Loading gallery...</p>
              </div>
            ) : galleryError ? (
              <div className="gallery-error">
                <p>{galleryError}</p>
                <button onClick={currentFolder ? () => fetchFolderImages(currentFolder.category, currentFolder.path) : fetchGalleryCategories} className="retry-button">
                  Retry
                </button>
              </div>
            ) : currentFolder ? (
              <>
                <div className="gallery-header">
                  <button className="back-button" onClick={handleBackToFolders}>
                    &larr; Back to Categories
                  </button>
                  <h4>{currentFolder.category.charAt(0).toUpperCase() + currentFolder.category.slice(1)} / {currentFolder.path}</h4>
                </div>
                
                {galleryImages.length === 0 ? (
                  <div className="no-images">
                    <p>No images found in this folder.</p>
                  </div>
                ) : (
                  <div className="gallery-grid">
                    {galleryImages.map((image, index) => {
                      console.log(`Rendering image ${index}:`, image.url);
                      return (
                        <div 
                          key={`image-${index}`} 
                          className="gallery-item"
                          onClick={() => handleImageClick(image)}
                        >
                          <img 
                            src={image.url} 
                            alt={image.name} 
                            onLoad={() => console.log(`Image loaded successfully: ${image.url}`)}
                            onError={(e) => {
                              console.error(`Failed to load image: ${image.url}`);
                              e.target.src = '/placeholder-image.svg';
                              e.target.alt = 'Image not found';
                            }} 
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            ) : (
              galleryCategories.length === 0 ? (
                <div className="no-folders">
                  <p>No categories available in the gallery.</p>
                </div>
              ) : (
                <>
                  <div className="gallery-categories">
                    {galleryCategories.map(category => {
                      if (category.folders.length === 0) return null;
                      
                      return (
                        <div key={category.path} className="gallery-category">
                          <h4>{category.name}</h4>
                          <div className="folders-grid">
                            {category.folders.map((folder, index) => (
                              <div 
                                key={`folder-${index}`} 
                                className="folder-item"
                                onClick={() => handleFolderClick(category.path, folder)}
                              >
                                <div className="folder-icon">
                                  {folder.isDirectImageFolder ? (
                                    <i className="folder-symbol">🖼️</i>
                                  ) : (
                                    <i className="folder-symbol">📁</i>
                                  )}
                                </div>
                                <div className="folder-name">
                                  {folder.name}
                                  {folder.imageCount > 0 && (
                                    <span className="image-count"> ({folder.imageCount})</span>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )
            )}
            
            {selectedImage && (
              <div className="image-modal-overlay" onClick={handleCloseModal}>
                <div className="image-modal" onClick={e => e.stopPropagation()}>
                  <div className="modal-header">
                    <h3>{selectedImage.name}</h3>
                    <div className="modal-actions">
                      <a 
                        className="download-button" 
                        href={selectedImage.url} 
                        download={selectedImage.name}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download
                      </a>
                      <button className="close-button" onClick={handleCloseModal}>
                        Close
                      </button>
                    </div>
                  </div>
                  <div className="modal-body">
                    <img 
                      src={selectedImage.url} 
                      alt={selectedImage.name} 
                      onError={(e) => {
                        console.error(`Failed to load image in modal: ${selectedImage.url}`);
                        e.target.src = '/placeholder-image.svg';
                        e.target.alt = 'Image not found';
                      }} 
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'links' && (
          <div className="links-tab">
            <div className="links-container">
              {/* DO NOT REMOVE THESE LINKS! THEY ARE REQUIRED!
                  IMPORTANT: THESE TWO LINKS MUST ALWAYS BE PRESENT!
                  THESE LINKS WERE SPECIFICALLY REQUESTED BY USER! */}
              <div className="link-card">
                <a
                  href="https://discord.gg/SGMCCwsd"
                  className="link-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Join Discord
                </a>
              </div>
              
              <div className="link-card">
                <a
                  href="http://47.7.21.116:30000/join"
                  className="link-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Launch Foundry
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      
      {confirmAction && (
        <div className="confirm-modal">
          <div className="confirm-content">
            <h3>Confirm Action</h3>
            <p>{confirmAction.message}</p>
            <div className="confirm-buttons">
              <button 
                className="confirm-button"
                onClick={confirmAction.action}
              >
                {confirmAction.confirmText}
              </button>
              <button 
                className="cancel-button"
                onClick={() => setConfirmAction(null)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard; 